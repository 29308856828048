

























































































































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  NavBar,
  Row,
  Col,
  Icon,
  Image,
  Progress,
  Popup,
  Button,
  List,
} from "vant";
import { printListDtos } from "@/shims.decorator";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Progress.name]: Progress,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [List.name]: List,
  },
})
export default class CollectPrintList extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return [];
    },
  })
  public printList!: Array<printListDtos>;
  // 将字符串日期转成时间戳
  dataFormat(dataString: string | undefined): number {
    let dataTmp = (dataString as string).replace(/-/g, "/");
    let timestamp = Date.parse(dataTmp);
    let newTime = new Date().getTime();
    return newTime - timestamp;
  }
  percentageValue(finishNumber: number, taskNumber: number): number {
    return parseInt(String((finishNumber / taskNumber) * 100));
  }
}
