var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PrintRecords"},_vm._l((_vm.printList),function(item,index){return _c('van-col',{key:index,staticClass:"printing-item"},[_c('van-row',{staticClass:"print-top"},[_c('van-col',{staticClass:"activity-name"},[_vm._v(_vm._s(item.activityName))]),_c('van-col',{staticClass:"print-record",on:{"click":function($event){return _vm.$router.push(("/print-records?activityId=" + (item.activityId)))}}},[_vm._v("印花记录 ")]),_c('van-icon',{staticClass:"icon",attrs:{"name":"arrow"}})],1),_c('van-row',{staticClass:"print-center"},[_c('van-col',{staticClass:"task-wrapper"},[_c('div',{staticClass:"left"},[_c('van-image',{staticClass:"print-img",staticStyle:{"object-fit":"fill"},attrs:{"fit":_vm.imageFit,"src":item.headImageUrl}})],1),_c('div',{staticClass:"right",class:{ disablePro: _vm.dataFormat(item.exchangeEndTime) >= 0 }},[_c('van-row',{staticClass:"task-progress"},[_c('h3',[_vm._v("任务进度")]),_c('div',{staticClass:"progress-wrapper"},[(
                  !isNaN(
                    _vm.percentageValue(item.finishTaskNumber, item.taskNumber)
                  )
                )?_c('van-progress',{attrs:{"percentage":_vm.percentageValue(item.finishTaskNumber, item.taskNumber),"stroke-width":10,"color":"#FF3E4E","track-color":"#F2F2F2","show-pivot":false}}):_vm._e()],1),_c('p',{staticClass:"progress-text"},[_c('span',{staticClass:"textA",class:{ activityEnd: item.activityOverdue }},[_vm._v(_vm._s(item.finishTaskNumber))]),_c('span',{attrs:{"color":"#303135"}},[_vm._v("/"+_vm._s(item.taskNumber))])])]),_c('van-row',{staticClass:"print-count"},[_c('h3',[_vm._v("集印花数")]),_c('div',{staticClass:"progress-wrapper"},[(
                  !isNaN(
                    _vm.percentageValue(
                      item.finishPrintingNumber,
                      item.printingNumber
                    )
                  )
                )?_c('van-progress',{attrs:{"percentage":_vm.percentageValue(
                    item.finishPrintingNumber,
                    item.printingNumber
                  ),"stroke-width":10,"color":"#FF3E4E","track-color":"#F2F2F2","show-pivot":false}}):_vm._e()],1),_c('p',{staticClass:"progress-text"},[_c('span',{staticClass:"textA",class:{ activityEnd: item.activityOverdue }},[_vm._v(_vm._s(item.finishPrintingNumber))]),_c('span',{attrs:{"color":"#303135"}},[_vm._v("/"+_vm._s(item.printingNumber))])])])],1)]),_c('van-col',{staticClass:"print-status"},[_c('van-button',{staticClass:"btn",attrs:{"disabled":item.activityOverdue},on:{"click":function($event){return _vm.$router.push(
              ("/print-activity?id=" + (item.activityId) + "&state=" + (item.activityState))
            )}}},[_vm._v(" "+_vm._s(item.activityOverdue ? "活动结束" : item.taskAllFinishFlag ? "已完成" : "去完成任务")+" ")])],1),_c('p',{staticClass:"line"})],1),_c('van-row',{staticClass:"print-bottom"},[_c('van-col',{staticClass:"bottom-left"},[_c('p',{staticClass:"exchange-print",class:{
            disabled: _vm.dataFormat(item.exchangeEndTime) >= 0,
          }},[_c('van-image',{staticStyle:{"width":"0.22rem","height":"0.26rem"},attrs:{"fit":_vm.imageFit,"src":("" + (_vm.dataFormat(item.exchangeEndTime) >= 0
                ? require('@/assets/images/CollectPrint/hua@2x.png')
                : require('@/assets/images/CollectPrint/lighthua@2x.png')))}}),_vm._v(" 可兑换印花 "),_c('span',[_vm._v(_vm._s(item.usableNum))])],1),_c('p',{staticClass:"end-times"},[_vm._v("截止时间"+_vm._s(item.exchangeEndTime))])]),_c('van-col',{staticClass:"bottom-right"},[_c('van-image',{staticStyle:{"width":"1.83rem","height":"0.49rem"},attrs:{"src":_vm.dataFormat(item.exchangeEndTime) >= 0
              ? require('@/assets/images/CollectPrint/Group 2@2x(1).png')
              : require('@/assets/images/CollectPrint/Group 2@2x.png')},on:{"click":function($event){return _vm.$router.push(("/print-exchange?activityId=" + (item.activityId)))}}})],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }